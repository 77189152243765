import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

import Layout from '../components/Layout'
import SEO from "../components/seo"
import { CardContent, Chip, List, ListItem, ListItemText, Paper, ListSubheader, Avatar, Typography } from "@material-ui/core"
import FlightLandIcon from "@material-ui/icons/FlightLand"
import DoneIcon from "@material-ui/icons/Done"
import StarIcon from "@material-ui/icons/StarBorder"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const pageTitle = "A curated list of relocation and visa support agencies."

const queryParamPattern = new RegExp(/\?.+=.*/g);
const extendUrlWithUTM = (url) => {
  return url + (queryParamPattern.test(url) ? "&" : "?") + "utm_source=workwithvisa.com&ref=workwithvisa.com";
}
const fixUrl = (url) => {
  return !url.startsWith('http') ? 'https://' + url : url;
}

const TagsPage = ({
  data: {
    allRelocationCompaniesJson: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
<Layout>
  <SEO title={pageTitle} description="A list of relocation and visa support agencies."/>
  <div>
    <Helmet title={pageTitle} />
    <Paper>
      <CardContent>
      <Chip
            icon={<FlightLandIcon />}
            label="Relocation and visa support agencies"
            color="primary"
            variant="outlined"
      />
      <br /><br />
      Curated list of relocation and visa support agencies.
      <br /><br />
      They provide wide variety of immigration services such as relocation advices, visa application, family relocation, work permit application and more!
      <br /><br />
      <p style={{display: "flex"}}>
        <Avatar variant="circle" style={{height: '1.4em', width: '1.4em', backgroundColor: '#5c6bc070',}} >
          <DoneIcon />
        </Avatar>
        <span style={{marginLeft: "16px", lineHeight: '1.4em'}}>Verified partner - provides best in class support and services</span> 
      </p>
      <p style={{display: "flex"}}>
        <Avatar variant="circle" style={{height: '1.4em', width: '1.4em', backgroundColor: '#5c6bc070',}} >
          <StarIcon />
        </Avatar>
        <span style={{marginLeft: "16px", lineHeight: '1.4em'}}>Most visited <span style={{fontSize: '0.6em'}}>(beta)</span> - based on the result of the last 7 days</span> 
      </p>
      <List>
        {group.map(tag => (
          <>
            <ListSubheader style={{backgroundColor: '#6574c4', borderRadius: "4px" }}>
              <Chip key={`chip-${tag.fieldValue}`} label={`${tag.fieldValue} (${tag.totalCount})`} size='small' clickable={false} />
            </ListSubheader>
            {tag.edges.map(edge => (
              <ListItem key={edge.node.companyName} dense divider>
                <ListItemText>
                  <a href={`${extendUrlWithUTM(fixUrl(edge.node.website))}`}>
                    <Chip key={`chip-${edge.node.companyName}`} label={`${edge.node.companyName}`} size='small' clickable={true} variant="outlined" onClick={e => {
                      // To stop the page reloading
                      e.preventDefault();
                      // Lets track that custom click
                      trackCustomEvent({
                        category: "Relocation",
                        action: "Click",
                        label: tag.fieldValue + "::" + edge.node.companyName ,
                      });
                      window.location.href = extendUrlWithUTM(fixUrl(edge.node.website));
                    }}/>
                  </a>
                </ListItemText>
                { edge.node.verified && <Avatar variant="circle" style={{height: '1.4em', width: '1.4em', backgroundColor: '#5c6bc070', marginRight: '4px'}}>
                  <DoneIcon />
                </Avatar>}
                {edge.node.favorite && <Avatar variant="circle" style={{height: '1.4em', width: '1.4em', backgroundColor: '#5c6bc070', marginRight: '4px'}}>
                  <StarIcon />
                </Avatar>}
              </ListItem>
            ))}
          </>
        ))}
      </List>
      </CardContent>
    </Paper>
  </div>
</Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allJobsJson: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allRelocationCompaniesJson {
      group(field: country) {
        fieldValue
        totalCount
        edges {
          node {
            companyName
            website
            verified
            favorite
          }
        }
      }
    }
  }
`